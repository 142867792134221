var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mb-3",attrs:{"id":"divCustomerPermissions"}},[_c('b-card',{attrs:{"title":"Customer Permissions","id":"crdCustomerPermissions"}},[_c('loading',{attrs:{"active":_vm.isLoading,"is-full-page":false,"color":"#114C8E"},on:{"update:active":function($event){_vm.isLoading=$event}}}),_c('b-row',{attrs:{"id":"rowMasterPermissions"}},[_c('b-col',{attrs:{"id":"colMasterPermissions"}},[_c('b-form-group',{attrs:{"id":"frmMasterPermissions","label":"Master Permissions","label-for":"ddlCustomerPermissons"}},[_c('treeselect',{class:_vm.inputValidations.chkState('customerPermissions', _vm.$v) === false
                ? 'is-invalid'
                : '',attrs:{"instanceId":"ddlCustomerPermissons","multiple":true,"options":_vm.masterPermissionOptions,"valueFormat":"object"},scopedSlots:_vm._u([{key:"option-label",fn:function({
                node,
                labelClassName,
                countClassName
              }){return _c('label',{class:labelClassName,attrs:{"id":"lblClassNamePermissions"}},[_vm._v(" "+_vm._s(node.label)+" "),(node.raw.description)?_c('span',{class:countClassName,attrs:{"id":"spnDescription"}},[_vm._v("("+_vm._s(node.raw.description)+")")]):_vm._e()])}}]),model:{value:(_vm.customerPermissions),callback:function ($$v) {_vm.customerPermissions=$$v},expression:"customerPermissions"}}),_c('b-form-invalid-feedback',{attrs:{"state":_vm.inputValidations.chkState('customerPermissions', _vm.$v)}},[_vm._v("Minimum of 1 permission required.")])],1)],1)],1),(!_vm.canAddImagePermissions)?_c('b-row',{attrs:{"id":"rowAddImagePermissions"}},[_c('b-col',{attrs:{"id":"colAddImagePermissions"}},[(!_vm.canAddImagePermissions)?_c('b-form-group',{attrs:{"id":"frmAddImagePermissions","label":"Image Permissions","label-for":"ddlCustomerImagePermissions"}},[_c('treeselect',{class:_vm.inputValidations.chkState('customerImagePermissions', _vm.$v) ===
              false
                ? 'is-invalid'
                : '',attrs:{"instanceId":"ddlCustomerImagePermissions","multiple":true,"options":_vm.imagePermissionOptions,"valueFormat":"object","disabled":_vm.canAddImagePermissions},model:{value:(_vm.customerImagePermissions),callback:function ($$v) {_vm.customerImagePermissions=$$v},expression:"customerImagePermissions"}}),_c('b-form-invalid-feedback',{attrs:{"id":"frmImagePermissions","state":_vm.inputValidations.chkState('customerImagePermissions', _vm.$v)}},[_vm._v("Minimum of 1 permission required.")])],1):_vm._e()],1)],1):_vm._e(),(_vm.canEdit)?_c('b-row',{attrs:{"id":"rowSaveCustomerPermissions"}},[_c('b-col',{attrs:{"id":"colSaveCustomerPermissions"}},[_c('b-button',{staticClass:"btn-block-xs-only",attrs:{"id":"btnSaveCustomerPermissions","variant":"primary"},on:{"click":function($event){return _vm.saveCustomer()}}},[_vm._v("Save Customer Permissions")]),_c('b-button',{staticClass:"ml-sm-3 mt-2 mt-sm-0 btn-block-xs-only",attrs:{"id":"btnResetCustomerPermissions","variant":"outline-danger"},on:{"click":function($event){return _vm.loadCustomerPermissions()}}},[_vm._v("Reset Customer Permissions")])],1)],1):_c('b-row',{attrs:{"id":"rowCustomerPermissionsWarning"}},[_c('b-col',{attrs:{"id":"colCustomerPermissionsWarning"}},[_c('div',{staticClass:"alert alert-warning",attrs:{"role":"alert","id":"altCustomerPermissionsWarning"}},[_vm._v(" KAG Internal Customer Permissions can not be edited. ")])])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }