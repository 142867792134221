<template id="tmplCustomerDataRestrictions">
  <div class="mb-3" id="divCustomerDataRestrictions">
    <b-card id="crdCustomerDataRestrictions">
      <b-card-title id="ttlCustomerDataRestrictions">
        Current Data Restrictions
        <i id="iCustomerDataRestrictions"
          class="fa fa-question-circle text-primary bottom-align-text"
          v-b-popover.hover.html="userDataSecurityPopoverText"
          title="Current Data Restrictions"
        ></i>
      </b-card-title>

      <b-row id="rowOneCurrentDataRestrictions">
        <b-col id="colCurrentDataRestrictions">
          <loading
            id="ldnCurrentDataRestrictions"
            :active.sync="isBusy"
            :is-full-page="false"
            color="#114C8E"
          ></loading>

          <b-table
            id="tblCurrentDataRestrictions"
            :items="stagings"
            :fields="fields"
            responsive="sm"
            striped
            outlined
            small
            :per-page="perPage"
            show-empty
          >
            <template v-slot:cell(isReadOnly)="data" id="tmplIsReadOnly">
              <b-form-checkbox :disabled="true" v-model="data.item.isReadOnly" id="cbxIsReadOnly">
              </b-form-checkbox>
            </template>

            <template v-slot:cell(autoAcceptTender)="data" id="tmplAutoAcceptTenderOne">
              <b-form-checkbox :disabled="true" v-model="data.item.autoAcceptTender" id="cbxAutoAcceptTenderOne">
              </b-form-checkbox>
            </template>


            <template v-slot:cell(deriveConsigneefromBillTo)="data" id="tmplDeriveConsigneefromBillTo">
              <b-form-checkbox :disabled="true" v-model="data.item.deriveConsigneefromBillTo" id="cbxDeriveConsigneefromBillTo">
              </b-form-checkbox>
            </template>

            <template v-slot:cell(allowedDocTypes)="data" id="tmplAllowedDocTypesOne">
              <span
                id="spnAllowedDocTypesOne"
                v-for="(item, index) in data.item.allowedDocTypes"
                :key="item"
              >
                {{ item }}
                <span v-if="index !== data.item.allowedDocTypes.length - 1" id="spnAllowedDocTypesLengthOne">
                  ,
                </span>
              </span>
            </template>

            <template v-slot:cell(actions)="data" id="tmplIsDeleting">
              <b-button
                id="btnIsDeleting"
                v-if="canEdit"
                size="sm"
                variant="danger"
                class="mb-1"
                :disabled="data.item.isDeleting"
                @click="deleteRelationshipFilter(data.item)"
              >
                <i class="fa fa-trash" id="iIsDeleting"></i>

                <span
                  id="spnIsDeleting"
                  v-if="data.item.isDeleting"
                  class="spinner-border spinner-border-sm ml-1"
                  role="status"
                  aria-hidden="true"
                ></span></b-button
            ></template>

            <template v-slot:empty="scope" id="tmplTextOne">
              <h5 class="text-center" id="h5TextOne">{{ scope.emptyText }}</h5>
            </template>
          </b-table>
          <b-pagination
            id="pgnTotalRecords"
            v-if="totalRecords > 0"
            size="md"
            :total-rows="totalRecords"
            v-model="currentPage"
            :per-page="perPage"
						@input="getPostData(currentPage)"
            first-number
            last-number
          >
          </b-pagination>
        </b-col>
      </b-row>

      <b-row class="mt-3" v-if="canEdit" id="rowAddDataRestrictions">
        <b-col cols="12" id="colCrdAddDataRestrictions">
          <h4 class="card-title" id="h4AddDataRestrictions">
            Add Data Restrictions
            <i id="iAddDataRestrictions"
              class="fa fa-question-circle text-primary bottom-align-text"
              v-b-popover.hover.html="userAddDataSecurityPopoverText"
              title="Add Data Restrictions"
            ></i>
          </h4>
        </b-col>
        <b-col lg="6" id="colDivisions">
          <b id="bDivisions">
            Divisions
          </b>
          <tf-division-multi-select
            :userId="loggedInUserId"
            :selectMultiple="false"
            :class="chkState('selectedDivisions') === false ? 'is-invalid' : ''"
          ></tf-division-multi-select>

          <b-form-invalid-feedback :state="chkState('selectedDivisions')"
            >At least 1 Division is required.</b-form-invalid-feedback
          >
        </b-col>
        <b-col lg="6" id="colBillTos">
          <b id="bBillTos">BillTos</b>
          <tf-bill-to-multi-select
            :userId="loggedInUserId"
            v-on:billTo-selected="billToSelected"
          ></tf-bill-to-multi-select>
        </b-col>
        <b-col lg="6" id="colConsignees">
          <b id="bConsignees">Consignees</b>
          <tf-consignee-multi-select
            :userId="loggedInUserId"
          ></tf-consignee-multi-select>
        </b-col>
        <b-col lg="6" id="colShippers">
          <b id="bShippers">Shippers</b>

          <tf-shipper-multi-select
            :userId="loggedInUserId"
          ></tf-shipper-multi-select>
        </b-col>
        <b-col lg="6" id="colSuppliers">
          <b id="bSuppliers">Suppliers</b>

          <tf-supplier-multi-select
            :userId="loggedInUserId"
          ></tf-supplier-multi-select>
        </b-col>
        <b-col lg="6" id="colAccountOf">
          <b id="bAccountOf">Account Of</b>

          <tf-account-of-multi-select
            :userId="loggedInUserId"
          ></tf-account-of-multi-select>
        </b-col>
        <b-col lg="6" id="colLoggedInUserId">
          <tf-commodity-multi-select
            :userId="loggedInUserId"
          ></tf-commodity-multi-select>
        </b-col>

        <b-col lg="6" id="colSubsidaries">
          <b id="bSubsidaries">
            Subsidaries
          </b>
          <tf-subsidiaries-multi-select></tf-subsidiaries-multi-select>
        </b-col>

        <b-col
          id="colImagePermissions"
          lg="6"
          v-if="imagePermissionOptions && imagePermissionOptions.length > 0"
        >
          <b
            id="bImagePermissions"
            >Image Permissions
            <i
              id="iImagePermissions"
              class="fa fa-question-circle text-primary bottom-align-text"
              v-b-popover.hover.html="imageDataSecurityPopoverText"
              title="Data Restriction image permissions"
            ></i
          ></b>
          <treeselect
            instanceId="ddlCustomerImagePermissions"
            v-model="customerImagePermissions"
            :multiple="true"
            :options="imagePermissionOptions"
            valueFormat="object"
          ></treeselect>
        </b-col>

        <b-col id="colParentPreviewIsReadOnly">
          <b-col lg="6" class="mb-4" id="colPreviewIsReadOnly">
            <b-form-checkbox
              id="chk-is-read-only"
              v-model="previewIsReadOnly"
              name="chk-is-read-only"
            >
              Read Only
              <i
                id="iPreviewIsReadOnly"
                class="fa fa-question-circle text-primary bottom-align-text"
                v-b-popover.hover.html="readOnlyDataSecurityPopoverText"
                title="Read Only Data Restrictions"
              ></i>
            </b-form-checkbox>
          </b-col>

          <b-col lg="6" class="mb-4" id="colPreviewAutoAcceptTender">
            <b-form-checkbox
              id="chk-auto-accept-tender"
              v-model="previewAutoAcceptTender"
              name="chk-auto-accept-tender"
            >
              Auto Accept Order
              <i
                id="iPreviewAutoAcceptTender"
                class="fa fa-question-circle text-primary bottom-align-text"
                v-b-popover.hover.html="autoAcceptOrderPopoverText"
                title="Auto Accept Order"
              ></i>
            </b-form-checkbox>
          </b-col>
        </b-col>
        <b-col id="colDeriveConsigneefromBillTo">
          <b-col lg="7" class="mb-4" id="colDeriveConsigneefromBillTo">
            <b-form-checkbox
              id="chk-DeriveConsigneefromBillTo"
              v-model="deriveConsigneefromBillTo"
              name="chk-DeriveConsigneefromBillTo"
            >
              Derive Consignee from BillTos
              <i
                id="iDeriveConsigneefromBillTo"
                class="fa fa-question-circle text-primary bottom-align-text"
                v-b-popover.hover.html="deriveConsigneefromBillToText"
                title="Derive Consignee From BillTo"
              ></i>
            </b-form-checkbox>
          </b-col>
        </b-col>
      </b-row>
      <b-row v-if="canEdit" class="mt-3" id="rowPreviewDataRestrictions">
        <b-col id="colPreviewDataRestrictions">
          <b-button
            id="btnPreviewDataRestrictions"
            variant="outline-primary"
            class=" btn-block-xs-only"
            @click="preview()"
            >Preview Data Restrictions

            <span
              id="spnPreviewDataRestrictions"
              v-if="isPreviewLoading"
              class="spinner-border spinner-border-sm ml-1"
              role="status"
              aria-hidden="true"
            ></span>
          </b-button>

          <b-button
            id="btnResetDataRestrictions"
            variant="outline-danger"
            class="ml-sm-3 mt-2 mt-sm-0 btn-block-xs-only"
            @click="resetRestrictions()"
            >Reset Data Restrictions</b-button
          >
        </b-col>
      </b-row>
      <b-row v-else id="altDataRestrictions">
        <b-col id="colDataRestrictions">
          <div class="alert alert-warning" role="alert" id="divDataRestrictions">
            KAG Internal Customer Data Restrictions can not be edited.
          </div>
        </b-col>
      </b-row>
      <b-row v-if="newStagings ? newStagings.length > 0 : false" class="mt-3" id="rowNewDataRestrictions">
        <b-col id="colNewDataRestrictions">
          <h4 class="card-title" id="h4NewDataRestrictions">New Data Restrictions to be created.</h4>

          <div
            id="altNewDataRestrictions"
            class="alert alert-warning"
            role="alert"
            v-if="showDivisionOnlyWarning"
          >
            You are about to create a division only relationship. This will
            allow users of this customer to access any data for the selected
            divisions in the system. <br />
            If this is not what you want to do, please use the reset button to
            start over.
          </div>

          <b-table
            :items="newStagings"
            :fields="fields"
            responsive="sm"
            striped
            outlined
            small
            show-empty
            id="tblNewStagings"
          >
            <template v-slot:cell(isReadOnly)="data" id="tmplNewStagings">
              <b-form-checkbox :disabled="true" v-model="data.item.isReadOnly" id="cbxNewStagings">
              </b-form-checkbox>
            </template>

            <template v-slot:cell(autoAcceptTender)="data" id="tmplAutoAcceptTenderTwo">
              <b-form-checkbox :disabled="true" v-model="data.item.autoAcceptTender" id="cbxAutoAcceptTenderTwo">
              </b-form-checkbox>
            </template>

            <template v-slot:cell(deriveConsigneefromBillTo)="data" id="tmplDeriveConsigneefromBillToTwo">
              <b-form-checkbox :disabled="true" v-model="data.item.deriveConsigneefromBillTo" id="cbxDeriveConsigneefromBillToTwo">
              </b-form-checkbox>
            </template>

            <template v-slot:cell(allowedDocTypes)="data" id="tmplAllowedDocTypesTwo">
              <span
                id="spnAllowedDocTypesTwo"
                v-for="(item, index) in data.item.allowedDocTypes"
                :key="item"
              >
                {{ item }}
                <span v-if="index !== data.item.allowedDocTypes.length - 1" id="spnAllowedDocTypesLengthTwo">
                  ,
                </span>
              </span>
            </template>

            <template v-slot:cell(actions)="data" id="tmplDeleteRelationshipPreviewFilter">
              <b-button
                size="sm"
                variant="danger"
                class="mb-1"
                id="btnDeleteRelationshipPreviewFilter"
                @click="deleteRelationshipPreviewFilter(data.item)"
              >
                <i class="fa fa-trash" id="iDeleteRelationshipPreviewFilter"></i> </b-button
            ></template>

            <template v-slot:empty="scope" id="tmplTextTwo">
              <h5 class="text-center" id="h5TextTwo">{{ scope.emptyText }}</h5>
            </template>
          </b-table>

          <b-button
            id="btnSaveDataRestrictions"
            variant="outline-primary"
            class=" btn-block-xs-only"
            @click="save()"
            >Save Data Restrictions

            <span
              id="spnSaveDataRestrictions"
              v-if="isSaveLoading"
              class="spinner-border spinner-border-sm ml-1"
              role="status"
              aria-hidden="true"
            ></span>
          </b-button>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from "vuex";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import { RepositoryFactory } from "@/services/RepositoryFactory";
import { handleRequestError } from "@/shared/utils/response-error-handler";
import _ from "lodash";
import { required } from "vuelidate/lib/validators";
import inputValidations from "@/shared/utils/input-validation";
import { KagInternalCustomerId } from "@/shared/utils/permissions";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import { Treeselect } from "@riophae/vue-treeselect";
import TfDivisionMultiSelect from "@/components/users/DivisionMultiSelect";
import TfBillToMultiSelect from "@/components/users/BillToMultiSelect";
import TfConsigneeMultiSelect from "@/components/users/ConsigneeMultiSelect";
import TfShipperMultiSelect from "@/components/users/ShipperMultiSelect";
import TfSupplierMultiSelect from "@/components/users/SupplierMultiSelect";
import TfAccountOfMultiSelect from "@/components/users/AccountOfMultiSelect";
import TfCommodityMultiSelect from "@/components/users/CommodityMultiSelect";
import TfSubsidiariesMultiSelect from "@/components/multiselects/SubsidiariesMultiSelect";

const CustomerRelationshipFilters = RepositoryFactory.get(
  "customerRelationshipFilters"
);
const RelationshipFiltersPreviewRepository = RepositoryFactory.get(
  "relationshipFiltersPreviewRepository"
);

export default {
  name:"CustomerDataRestrictions.vue",

  props: ["loggedInUserId", "selectedCustomer"],

  components: {
    Loading,
    TfDivisionMultiSelect,
    TfBillToMultiSelect,
    TfConsigneeMultiSelect,
    TfShipperMultiSelect,
    TfSupplierMultiSelect,
    TfAccountOfMultiSelect,
    TfCommodityMultiSelect,
    TfSubsidiariesMultiSelect,
    Treeselect
  },

  data() {
    return {
      isBusy: false,
      isCurrentRestrictionsLoaded: false,
      isBeingDeleted: false,
      isPreviewLoading: false,
      isSaveLoading: false,
      currentPage: 1,
      perPage: 10,
      stagings: [],
      totalRecords: 0,
      newStagings: [],
      previewIsReadOnly: true,
      previewAutoAcceptTender: true,
      deriveConsigneefromBillTo:false,
      customerImagePermissions: [],
      showDivisionOnlyWarning: false,
      fields: [
        { key: "division", label: "Source" },
        { key: "billTo", label: "BillTo" },
        { key: "consignee", label: "Consignee" },
        { key: "shipper", label: "Shipper" },
        { key: "supplier", label: "Supplier" },
        { key: "accountOf", label: "Account Of" },
        // { key: "commodityClass", label: "Commodity Class" },
        { key: "commodity", label: "Commodity" },
        { key: "subsidiary", label: "Subsidiary" },
        { key: "isReadOnly", label: "Read Only" },
        { key: "autoAcceptTender", label: "Auto Accept"},
        { key: "deriveConsigneefromBillTo", label: "Derive Consignee" },
        { key: "allowedDocTypes", label: "Images" },
        // { key: "masterOrderNumber", label: "Master Order #" },
        //{ key: "pin", label: "Pin" },
        { key: "actions", label: "Actions" }
      ],
      inputValidations: inputValidations
    };
  },

  async mounted() {
    await this.getPostData(1);
  },

  methods: {
    ...mapActions("companyMultiSelects", ["clearState"]),

    chkState(val) {
      const field = this.$v[val];
      return !field.$dirty || !field.$invalid;
    },

    async getPostData(currentPage) {
      this.isBusy = true;

      let skip = (currentPage - 1) * this.perPage;

      const requestData = {
        jwtToken: this.$store.state.user.jwtToken,
        customerId: this.selectedCustomer.id,
        skip: skip,
        take: this.perPage
      };

      CustomerRelationshipFilters.get(requestData)
        .then(res => {
          this.stagings = [];
					if(res.data){
						res.data.results.forEach(element => {
							element.isDeleting = false;
							this.stagings.push(element);
						});
	
						this.totalRecords = res.data.totalRecords;
					}
        })
        .catch(error => {
          console.log(error);
        })
        .finally(() => {
          this.isBusy = false;
        });
    },

    async preview() {
      this.$v.$touch();

      if (this.$v.$invalid) {
        return;
      }

      this.isPreviewLoading = true;
      this.newStagings = [];
      const requestData = {
        jwtToken: this.$store.state.user.jwtToken, //the token of the user editing this user
        postData: {
          customerId: this.selectedCustomer.id,
          divisions: this.getSelectedDivisions //TODO: Need to validate and make them select at least 1 division.
            ? this.getSelectedDivisions.map(a => a.source)
            : "FDG",
          billTos: this.getSelectedBillTos.map(a => a.id),
          consignees: this.getSelectedConsignees.map(a => a.id),
          shippers: this.getSelectedShippers.map(a => a.id),
          suppliers: this.getSelectedSuppliers.map(a => a.id),
          accountOfs: this.getSelectedAccountOfs.map(a => a.id),
          commodities: this.getSelectedCommodities.map(a => a.commodityCode),
          subsidiaries: this.getSelectedSubsidiaries.map(a => a.abbreviation),
          isReadOnly: this.previewIsReadOnly,
          autoAcceptTender: this.previewAutoAcceptTender,
          deriveConsigneefromBillTo:this.deriveConsigneefromBillTo,
          allowedDocTypes: this.customerImagePermissions.map(a => a.id)
        }
      };

      if (
        this.getSelectedBillTos.length === 0 &&
        this.getSelectedConsignees.length === 0 &&
        this.getSelectedShippers.length === 0 &&
        this.getSelectedSuppliers.length === 0 &&
        this.getSelectedAccountOfs.length === 0 &&
        this.getSelectedCommodities.length === 0 &&
        this.getSelectedSubsidiaries.length === 0
      ) {
        this.showDivisionOnlyWarning = true;
      } else {
        this.showDivisionOnlyWarning = false;
      }

      try {
        const response = await RelationshipFiltersPreviewRepository.post(
          requestData
        );

        response.data.records.forEach(element => {
          element.isDeleting = false;
          this.newStagings.push(element);
        });
      } catch (error) {
        const errorMessage = handleRequestError(error);
        this.$snotify.error(errorMessage, "Error");
      } finally {
        this.isPreviewLoading = false;
      }
    },

    async save() {
      this.isSaveLoading = true;

      let itemsToPost = [];
      this.newStagings.forEach(s => {
        let relationship = {
          customerId: this.selectedCustomer.id,
          division: s.division ? s.division : "FDG", //TODO: Need to validate and make them select at least 1 division.
          billTo: s.billTo,
          consignee: s.consignee,
          shipper: s.shipper,
          supplier: s.supplier,
          accountOf: s.accountOf,
          commodity: s.commodity,
          subsidiary: s.subsidiary,
          isReadOnly: s.isReadOnly,
          allowedDocTypes: s.allowedDocTypes,
          deriveConsigneefromBillTo: s.deriveConsigneefromBillTo,
          autoAcceptTender: s.autoAcceptTender
        };
        itemsToPost.push(relationship);
      });


      const requestData = {
        jwtToken: this.$store.state.user.jwtToken, //the token of the user editing this user
        postData: itemsToPost
      };

      try {
        await CustomerRelationshipFilters.post(requestData);
				
        this.getPostData(this.currentPage);

        this.newStagings = [];
        this.resetRestrictions();

        this.$snotify.success(
          `Customer Data Restrictions added successfully.`,
          "Success",
          {
            timeout: 700,
            showProgressBar: true
          }
        );
      } catch (error) {
        const errorMessage = handleRequestError(error);
        this.$snotify.error(errorMessage, "Error");
      } finally {
        this.isSaveLoading = false;
      }
    },

    async deleteRelationshipFilter(item) {
      item.isDeleting = true;

      const requestData = {
        jwtToken: this.$store.state.user.jwtToken,
        id: item.id
      };

      try {
        await CustomerRelationshipFilters.delete(requestData);

        this.stagings = this.stagings.filter(w => w.id !== item.id);
        this.totalRecords = this.stagings.length;
        this.$snotify.success(
          `Customer filter deleted successfully.`,
          "Success",
          {
            timeout: 700,
            showProgressBar: true
          }
        );

        // await this.getPostData(this.currentPage);
        // this.totalRecords = this.stagings.length;
      } catch (error) {
        const errorMessage = handleRequestError(error);
        this.$snotify.error(errorMessage, "Error");
      } finally {
        item.isDeleting = false;
      }
    },

    async deleteRelationshipPreviewFilter(item) {
      item.isDeleting = true;

      this.newStagings = this.newStagings.filter(w => w.id !== item.id);
    },

    resetRestrictions() {
      this.clearState();
      this.newStagings = [];
      this.customerImagePermissions = [];
      this.$v.$reset();
    },

    billToSelected() {
      this.previewIsReadOnly = false;
    }
  },
  computed: {
    ...mapState("companyMultiSelects", ["selectedDivisions"]),

    ...mapGetters({
			// CompanyMultiSelects module getters
			getSelectedDivisions: "companyMultiSelects/getSelectedDivisions",
			getSelectedBillTos: "companyMultiSelects/getSelectedBillTos",
			getSelectedConsignees: "companyMultiSelects/getSelectedConsignees",
			getSelectedShippers: "companyMultiSelects/getSelectedShippers",
			getSelectedSuppliers: "companyMultiSelects/getSelectedSuppliers",
			getSelectedAccountOfs: "companyMultiSelects/getSelectedAccountOfs",
			getSelectedCommodities: "companyMultiSelects/getSelectedCommodities",
			getSelectedSubsidiaries: "companyMultiSelects/getSelectedSubsidiaries",

			// Customers module getters
			getGlobalCustomerDocTypes: "customers/getGlobalCustomerDocTypes",
			getGlobalMasterPermissions: "customers/getGlobalMasterPermissions",
			getSelectedCustomerDocTypes: "customers/getSelectedCustomerDocTypes"
		}),


    imagePermissionOptions() {
      const globalImagePermissions = this.getGlobalCustomerDocTypes;
      const imagePermissions = this.getSelectedCustomerDocTypes;

      const ip = [];

      globalImagePermissions.forEach(imagePermission => {
        let index = _.findIndex(imagePermissions, function(o) {
          return o.documentType === imagePermission.docType;
        });

        if (index > -1) {
          ip.push({
            id: imagePermission.docType,
            label: imagePermission.name
          });
        }
      });

      return ip;
    },

    userDataSecurityPopoverText() {
      return (
        "<p>This section is specific to this customer. Any restrictions set here will be the only data that any users of this customer is allowed to access.</p>" +
        "<p>If no restrictions are set, this customer will not be able to see any data in the system.</p>" +
        "<p>For example, if a customer has 3 billtos, it would be restricted here.</p>"
      );
    },

    userAddDataSecurityPopoverText() {
      return (
        "<p id='p1'>Use this section to add new Data Restrictions</p>" +
        "<p id='p2'>You can add any combination of data that the Customer should be allowed to view</p>" +
        "<p id='p3'>For example, if you add 3 billtos to this customer, the users belonging to this customer would be able to see anything tied to those billtos.</p>"
      );
    },

    readOnlyDataSecurityPopoverText() {
      return (
        "<p id='p4'>If a restriction is marked read only, the customer will not be able to tender orders for items matching the restriction.</p>" +
        "<p id='p5'>For example, if you add an account of record, and a supplier record to the customer marked as read only, then when retrieving consignees for order tendering those records would be ignored.</p>"
      );
    },

    imageDataSecurityPopoverText() {
      return (
        "<p id='p6'>These image permissions will be specific to the created data restrictions.</p>" +
        "<p id='p7'>For example, if you add an account of record with only DR and BOL image permissions, then any order matching the record will only have access to those 2 image types.</p>"
      );
    },

    autoAcceptOrderPopoverText() {
      return (
        "<p id='p8'>If Auto Accept Order is marked, when creating orders they will be automatically accepted and set as available.</p>" +
        "<p id='p9'>If Auto Accept Order is not marked, when creating orders they will be created as Pending orders and waiting for manual approval.</p>"
      );
    },

    deriveConsigneefromBillToText() {
      return (
        "<p id='p10'>If Derive Consignee from BillTos is marked it will allow for a derived combination of Billto/Consignee/Shipper</p>" +
        "<p id='p11'>If Derive Consignee from BillTos is not marked only those records with explicit BillTo/Consignee/Shipper should be available</p>"
      );
    },

    KagInternalCustomerId() {
      return KagInternalCustomerId;
    },

    canEdit() {
      return (
        this.selectedCustomer.id.toUpperCase() !==
        KagInternalCustomerId.toUpperCase()
      );
    }
  },

  validations: {
    selectedDivisions: {
      required
    }
  },

  watch: {
    'selectedDivisions': function() {
      const selectedDivisions = JSON.parse(JSON.stringify(this.getSelectedDivisions.map(a => a.source)));
      this.previewAutoAcceptTender = selectedDivisions[0] === "FDG";
    }
  },

  destroyed() {
    this.clearState(1);
  }
};

</script>

<style scoped>
div.is-invalid div {
  border-color: #c22032;
}

div.is-invalid ~ {
  display: block;
}
</style>
