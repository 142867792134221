<template id="tmplCustomerPermissions">
  <div class="mb-3" id="divCustomerPermissions">
    <b-card title="Customer Permissions" id="crdCustomerPermissions">
      <loading
        :active.sync="isLoading"
        :is-full-page="false"
        color="#114C8E"
      ></loading>
      <b-row id="rowMasterPermissions">
        <b-col id="colMasterPermissions">
          <b-form-group
            id="frmMasterPermissions"
            label="Master Permissions"
            label-for="ddlCustomerPermissons"
          >
            <treeselect
              instanceId="ddlCustomerPermissons"
              v-model="customerPermissions"
              :multiple="true"
              :options="masterPermissionOptions"
              valueFormat="object"
              :class="
                inputValidations.chkState('customerPermissions', $v) === false
                  ? 'is-invalid'
                  : ''
              "
            >
              <label
                id="lblClassNamePermissions"
                slot="option-label"
                slot-scope="{
                  node,
                  labelClassName,
                  countClassName
                }"
                :class="labelClassName"
              >
                {{ node.label }}
                <span v-if="node.raw.description" :class="countClassName" id="spnDescription"
                  >({{ node.raw.description }})</span
                >
              </label>
            </treeselect>

            <b-form-invalid-feedback
              :state="inputValidations.chkState('customerPermissions', $v)"
              >Minimum of 1 permission required.</b-form-invalid-feedback
            >
          </b-form-group>
        </b-col>
      </b-row>
      <b-row v-if="!canAddImagePermissions" id="rowAddImagePermissions">
        <b-col id="colAddImagePermissions">
          <b-form-group
            id="frmAddImagePermissions"
            label="Image Permissions"
            label-for="ddlCustomerImagePermissions"
            v-if="!canAddImagePermissions"
          >
            <treeselect
              instanceId="ddlCustomerImagePermissions"
              v-model="customerImagePermissions"
              :multiple="true"
              :options="imagePermissionOptions"
              valueFormat="object"
              :disabled="canAddImagePermissions"
              :class="
                inputValidations.chkState('customerImagePermissions', $v) ===
                false
                  ? 'is-invalid'
                  : ''
              "
            ></treeselect>
            <b-form-invalid-feedback
              id="frmImagePermissions"
              :state="inputValidations.chkState('customerImagePermissions', $v)"
              >Minimum of 1 permission required.</b-form-invalid-feedback
            >
          </b-form-group>
        </b-col>
      </b-row>
      <b-row v-if="canEdit" id="rowSaveCustomerPermissions">
        <b-col id="colSaveCustomerPermissions">
          <b-button
            id="btnSaveCustomerPermissions"
            variant="primary"
            class=" btn-block-xs-only"
            @click="saveCustomer()"
            >Save Customer Permissions</b-button
          >
          <b-button
            id="btnResetCustomerPermissions"
            variant="outline-danger"
            class="ml-sm-3 mt-2 mt-sm-0 btn-block-xs-only"
            @click="loadCustomerPermissions()"
            >Reset Customer Permissions</b-button
          >
        </b-col>
      </b-row>
      <b-row v-else id="rowCustomerPermissionsWarning">
        <b-col id="colCustomerPermissionsWarning">
          <div class="alert alert-warning" role="alert" id="altCustomerPermissionsWarning">
            KAG Internal Customer Permissions can not be edited.
          </div>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import { RepositoryFactory } from "@/services/RepositoryFactory";
import { required, requiredIf } from "vuelidate/lib/validators";
import inputValidations from "@/shared/utils/input-validation";
import { KagInternalCustomerId } from "@/shared/utils/permissions";
import { handleRequestError } from "@/shared/utils/response-error-handler";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import { Treeselect } from "@riophae/vue-treeselect";
const CustomerRepository = RepositoryFactory.get("customersRepository");

export default {
  name:"CustomerPermissions.vue",
  props: ["customerId"],
  components: {
    Treeselect,
    Loading
  },
  data() {
    return {
      customerName: null,
      customerPermissions: [],
      customerImagePermissions: [],
      inputValidations: inputValidations,
      isLoading: false
    };
  },
  async created() {
    await this.loadCustomerPermissions();
  },
  watch: {
    masterPermissionOptions() { // watch masterPermissionOptions because on first creation they are not loaded during created hook
      this.loadCustomerPermissions();
    }
  },
  methods: {
    ...mapActions("customers", ["setSelectedCustomerDocTypes"]),
    async loadCustomerPermissions() {
      this.isLoading = true;

      let requestData = {
        jwtToken: this.$store.state.user.jwtToken,
        id: this.customerId
      };

      requestData = {
        jwtToken: this.$store.state.user.jwtToken,
        id: this.customerId
      };

      try {
        let response = await CustomerRepository.getCustomerPermissions(
          requestData
        );

        this.customerPermissions = this.masterPermissionOptions.filter(masterPermissionOption =>
						response.data.allowedPermissions.includes(masterPermissionOption.id)
						);

        this.customerImagePermissions = this.imagePermissionOptions.filter(imagePermissionOption =>
						response.data.allowedDocTypes.includes(imagePermissionOption.id)
				);

        this.setSelectedCustomerDocTypes(response.data.allowedDocumentTypes);
      } catch (error) {
        console.log(error);
        const errorMessage = handleRequestError(error);
        this.$snotify.error(errorMessage, "Error");
      }

      this.isLoading = false;
    },
    async saveCustomer() {
      this.isLoading = true;

      try {
        this.$v.$touch();

        if (!this.$v.$invalid) {
          this.isLoading = true;

          const requestData = {
            jwtToken: this.$store.state.user.jwtToken,
            customerId: this.customerId,
            data: {
              customerId: this.customerId,
              allowedPermissions: this.customerPermissions.map(a => a.id),
              allowedDocTypes: this.customerImagePermissions.map(a => a.id)
            }
          };

          const user = await CustomerRepository.updateCustomerPermissions(
            requestData
          );

          this.isLoading = false;

          this.setSelectedCustomerDocTypes(user.data.allowedDocumentTypes);

          this.$snotify.success(
            `Customer Permissions updated successfully.`,
            "Success",
            {
              timeout: 1500,
              showProgressBar: true
            }
          );
        }
      } catch (error) {
        console.log(error);

        const errorMessage = handleRequestError(error);
        this.$snotify.error(errorMessage, "Error");
      } finally {
        this.isLoading = false;
      }
    }
  },
  computed: {
    ...mapGetters("customers", [
      "getGlobalCustomerDocTypes",
      "getGlobalMasterPermissions"
    ]),
    masterPermissions() {
      return this.$store.state.internalAdminMasterPermission.masterPermissions;
    },
    masterPermissionOptions() {
      const mp = this.getGlobalMasterPermissions; //this.$store.state.internalAdminMasterPermission.masterPermissions;

      const treeOptions = [];

      mp.forEach(p => {
        const objMp = {
          id: p.id,
          label: p.permission,
          description: p.description
        };
        treeOptions.push(objMp);
      });

      return treeOptions;
    },
    imagePermissionOptions() {
      const imagePermissions = this.getGlobalCustomerDocTypes; //this.$store.state.internalAdminImagePermission.imagePermissions;

      const ip = [];
      imagePermissions.forEach(imagePermission => {
        ip.push({
          id: imagePermission.docType,
          label: imagePermission.name
        });
      });

      return ip;
    },
    canAddImagePermissions() {
      let canAddImages = false;
      this.customerPermissions.forEach(cp => {
        // id 5 is image permissions brosuf
        if (cp.id === 5) canAddImages = true;
      });
      return !canAddImages;
    },
    KagInternalCustomerId() {
      return KagInternalCustomerId;
    },
    canEdit() {
      return (
        this.customerId.toUpperCase() !== KagInternalCustomerId.toUpperCase()
      );
    }
  },
  validations: {
    customerPermissions: {
      required
    },

    customerImagePermissions: {
      required: requiredIf(function() {
        return !this.canAddImagePermissions;
      })
    }
  }
};

</script>
